// Shipping Calculator
/**
 * @namespace
*/
var generic = generic || {};
var site = site || {};
site.countryInfo = {};
site.hasInitLocationDropdown = 0;
site.rb = site.rb || {};
site.shippingCalc = site.shippingCalc || {};
site.shippingCalc.init = function() {
  if (site.client.isMobile) {
    site.shippingCalc.widgetCointainer = $('ul.spp_view').find('.spp-product');
  } else {
    site.shippingCalc.widgetCointainer = $('.spp_page_wrap').find('.icons');
  }
  site.shippingCalc.addressCointainer = $('.shipping-calc-cointainer');
  if (site.shippingCalc.widgetCointainer || site.shippingCalc.addressCointainer) {
    site.shippingCalc.getShippingCalcValue();
  }
  if (site.shippingCalc.addressCointainer.length > 0) {
    site.shippingCalc.addressBookShippingCalc();
  }
  site.shippingCalc.getuserAddress();
};
// Get calculated shipping price based on the cookie value
site.shippingCalc.getShippingCalcValue = function() {
  var shipping_data = generic.cookie('shippingResult');
  if (shipping_data) {
    site.shippingCalc.displayShippingWidget(shipping_data);
  } else if (typeof site.userInfoCookie.getValue('signed_in') !== 'undefined' && site.userInfoCookie.getValue('signed_in') > 0) {
    site.shippingCalc.getuserAddress();
  }
  if (site.shippingCalc.widgetCointainer.length > 0 && $('.shipping_calc').length === 0) {
    var shippingCalcLink = '<a class="shipping_calc">' + site.shippingCalc.shipping_calc_title + '</a>';
    shippingCalcLink = site.client.isMobile ? '<li class="shipping-calc-link">' + shippingCalcLink + '</li>' : shippingCalcLink;
    site.shippingCalc.widgetCointainer.after(shippingCalcLink);
    site.shippingCalc.initShippingCalc();
  }
};

// Init shipping calculation function
site.shippingCalc.initShippingCalc = function() {
  $('.shipping_calc').on('click', function(event) {
    event.preventDefault();
    var file = '/templates/shipping_calc.tmpl';
    generic.template.get({
      path: file,
      forceReload: true,
      callback: function(html) {
        var options = {
          width: 500,
          height: 550,
          includeBackground: true,
          includeCloseLink: true,
          hideOnClickOutside: true
        };
        options.content = html;
        generic.overlay.launch(options);
        var overlayContainer = $('#ship-calc-overlay');
        var closePopup = $('.close-popup');
        if (!overlayContainer[0]) {
          return null;
        }
        site.shippingCalc.initFormSubmit({
          container: overlayContainer
        });
        $('.calculation-result').removeClass('hidden');
        closePopup.on('click', function(e) {
          e.preventDefault();
          generic.overlay.hide();
        });
      }
    });
  });
};
// Init shipping calculation form submit
site.shippingCalc.initFormSubmit = function(args) {
  if (!args.container[0]) {
    return null;
  }
  var container = args.container;
  var formNode = container.find('form');
  var loadingNode = container.find('.loading');
  var errorNode = container.find('.error_messages');
  var submitBtn = container.find('.input-btn');
  var regionPickPointNode = container.find('.region');
  var citySelectPickPointNode = container.find('.city');

  var calculationResult = container.find('.calculation-result');
  // pick point dropdown init
  var rpcMethod = 'ShippingCalc.getLocationData';
  site.pickPointGetLocationsList(rpcMethod);
  site.initLocationDropdownOptions({
    regionSelectNode: regionPickPointNode,
    citySelectNode: citySelectPickPointNode,
    regionValue: regionPickPointNode.val(),
    cityValue: citySelectPickPointNode.val()
  });
  // init form submit
  submitBtn.on('click', function(evt) {
    evt.preventDefault();
    var inputs = formNode.find(':input');
    var formArgs = {};
    for (var i = 0; i < inputs.length; i++) {
      var input = inputs[i];
      var name = $(input).attr('name');
      if (name) {
        var val = input.value;
        formArgs[name] = val;
      }
    }
    var country_condition = $('select#form--shipping_calculator--field--COUNTRY_ID').children().val() === formArgs.COUNTRY_ID ? true : false;
    submitBtn.addClass('hidden');
    loadingNode.removeClass('hidden');
    site.shippingCalc.formSubmit({
      params: formArgs,
      callback: function(jsonRpcResponse) {
        var shippingData = jsonRpcResponse.getData();
        shippingData = shippingData ? shippingData.shippincinfo : '';
        generic.template.get({
          path: '/templates/shipping-calc-results.tmpl',
          object: shippingData,
          callback: function(html) {
            if (country_condition && (!formArgs.REGION || formArgs.REGION === '0' || !formArgs.CITY || formArgs.CITY === '0')) {
              if ($('.missing_region').length > 1) {
                $('.missing_region').eq(0).remove();
              }
              if ($('.missing_city').length > 1) {
                $('.missing_city').eq(0).remove();
              }
              loadingNode.addClass('hidden');
              return false;
            }
            calculationResult.html(html);
            site.shippingCalc.setCookie({
              formArgs: formArgs,
              shippingData: shippingData
            });
            site.shippingCalc.getShippingCalcValue();
            errorNode.html('');
            submitBtn.removeClass('hidden');
            loadingNode.addClass('hidden');
          }
        });
      },
      errorCallBack: function(jsonRpcResponse) {
        var errorObjectsArray = jsonRpcResponse.getMessages();
        generic.showErrors(errorObjectsArray, errorNode);
        submitBtn.removeClass('hidden');
        loadingNode.addClass('hidden');
      }
    });
    if ($('select#form--shipping_calculator--field--COUNTRY_ID').val() === '81') {
      if ($('select#form--shipping_calculator--field--REGION').val() === '0') {
        $('#ship-calc-overlay').find('#form--errors--shipping_calculator').after('<p class="missing_region">Пожалуйста, выберите область и город.</p>');
        $('select#form--shipping_calculator--field--REGION').addClass('error');
        $('select#form--shipping_calculator--field--CITY').addClass('error');
        $('.calculation-result').addClass('hidden');
      } else if ($('select#form--shipping_calculator--field--REGION').val() !== '0' && $('select#form--shipping_calculator--field--CITY').val() === '0') {
        $('#ship-calc-overlay').find('#form--errors--shipping_calculator').after('<p class="missing_city">Пожалуйста, выберите город.</p>');
        $('select#form--shipping_calculator--field--REGION').removeClass('error');
        $('select#form--shipping_calculator--field--CITY').addClass('error');
        $('.missing_region').addClass('hidden');
        $('.calculation-result').addClass('hidden');
      } else {
        $('select#form--shipping_calculator--field--REGION').removeClass('error');
        $('select#form--shipping_calculator--field--CITY').removeClass('error');
        $('.calculation-result').removeClass('hidden');
        $('.missing_region').addClass('hidden');
        $('.missing_city').addClass('hidden');
      }
    } else {
      $('select#form--shipping_calculator--field--REGION').removeClass('error');
      $('select#form--shipping_calculator--field--CITY').removeClass('error');
      $('.calculation-result').removeClass('hidden');
      $('.missing_region').addClass('hidden');
      $('.missing_city').addClass('hidden');
    }
    $('select#form--shipping_calculator--field--COUNTRY_ID').on('change', function() {
      $('.calculation-result').addClass('hidden');
    });
    $('select#form--shipping_calculator--field--REGION').on('change', function() {
      $('.calculation-result').addClass('hidden');
    });
    $('select#form--shipping_calculator--field--CITY').on('change', function() {
      $('.calculation-result').addClass('hidden');
    });
  });
};

site.shippingCalc.formSubmit = function(args) {
  if (!args || !args.callback) {
    return null;
  }
  generic.jsonrpc.fetch({
    method: 'form.rpc',
    params: [args.params],
    onSuccess: function(jsonRpcResponse) {
      args.callback(jsonRpcResponse);
    },
    onFailure: function(jsonRpcResponse) {
      args.errorCallBack(jsonRpcResponse);
    }
  });
};
// Set cookie for calculation results
site.shippingCalc.setCookie = function(args) {
  var formArgs = args.formArgs;
  var city_name;
  var shippingData = args.shippingData;
  city_name = formArgs.CITY || formArgs.city;
  shippingData['city'] = typeof city_name !== 'undefined' ? city_name + ',' : '';
  shippingData['COUNTRY_ID'] = formArgs.COUNTRY_ID;
  shippingData['REGION'] = formArgs.REGION;
  shippingData = JSON.stringify(shippingData);
  generic.cookie('shippingResult', shippingData, { path: '/' });
};

$(document).ready(function(e) {
  site.shippingCalc.getRBKeys();
  site.shippingCalc.init();
});

site.shippingCalc.getRBKeys = function() {
  var rb = generic.rb('language');
  site.shippingCalc.shipping_calc_title = rb.shipping_calc_title || 'Рассчитать стоимость доставки';
};

site.shippingCalc.displayShippingWidget = function(shipping_data) {
  generic.jsonrpc.fetch({
    'method': 'trans.get',
    'params': [{
      'trans_fields': [],
      'payment_fields': [],
      'order_fields': ['items']
    }],
    'onSuccess': function(d) {
      var cartResponse = d.getValue();
      if (typeof cartResponse === 'undefined' || cartResponse === null) return;
      var subtotal = cartResponse.items_count > 0 ? cartResponse.subtotal : 0;
      var shipping_free = shipping_data.freeShippingPrice.split(' ');
      var shipping_calculation = shipping_free[0] - subtotal;
      if (parseInt(shipping_calculation) < 0 || isNaN(shipping_calculation)) {
        shipping_data['freeShippingPrice'] = 0;
        shipping_data['free_shipping_yes'] = 'hidden';
      } else {
        shipping_data['freeShippingPrice'] = shipping_calculation + ' ' + shipping_free[1];
        shipping_data['free_shipping_yes'] = 'hidden';
      }
      if (shipping_data.COUNTRY_ID !== '81') {
        shipping_data['free_shipping_yes'] = 'hidden';
        shipping_data['city'] = '';
        shipping_data['REGION'] = '';
        $('.free_shipping_text').css('display', 'none');
      } else {
        $('.display_non_pro_user').removeClass('hidden');
        $('.free_shipping_text').css('display', 'block');
      }
      var city_value = shipping_data['city'].split(',');
      if (shipping_data['REGION'] === city_value[0]) {
        shipping_data['city'] = '';
      }
      site.shippingCalc.getShippingdata(shipping_data);
      if (shipping_data && shipping_data['price'] !== null) {
        $('.shipping-cost-widget').css('display', 'block');
      } else if (shipping_data['price'] == null) {
        $('.shipping-price').css('display', 'none');
      } else if (shipping_data['first_available_delivery'] == null) {
        $('.country-container').css('display', 'none');
      } else if (shipping_data['city'] == null && shipping_data['price'] == null && shipping_data['first_available_delivery'] == null) {
        $('.shipping_cost_text').css('display', 'none');
      } else {
        $('.shipping-cost-widget').css('display', 'none');
      }
    },
    'onFailure': function() {
      return 0;
    }
  });
};

site.shippingCalc.getShippingdata = function(shipping_data) {
  generic.template.get({
    path: '/templates/shipping-calc-widget.tmpl',
    object: shipping_data,
    callback: function(html) {
      if (site.shippingCalc.addressCointainer.length > 0) {
        site.shippingCalc.addressCointainer.html(html);
      } else {
        var shippingCalcResults = site.client.isMobile ? '<li class="shipping-calc-results">' + html + '</li>' : html;
        $('.shipping-cost-widget').remove();
        site.shippingCalc.widgetCointainer.after(shippingCalcResults);
        if (shipping_data && shipping_data['price'] !== null) {
          $('.shipping-cost-widget').css('display', 'block');
        }
      }
    }
  });
};

site.shippingCalc.addressBookShippingCalc = function(shippingData) {
  var addressArray = shippingData || window.userAddressesArray;
  if (typeof addressArray !== 'undefined' && addressArray.length > 0) {
    addressArray = site.shippingCalc.getDefaultShippingAddress(addressArray);
    var formArgs = {};
    formArgs['country_id'] = addressArray.COUNTRY_ID;
    formArgs['region'] = '';
    formArgs['city'] = addressArray.CITY;
    generic.jsonrpc.fetch({
      'method': 'order.getShippingPrice',
      'params': [formArgs],
      'onSuccess': function(d) {
        var shippingData = d.getValue();
        site.shippingCalc.setCookie({
          formArgs: formArgs,
          shippingData: shippingData
        });
        site.shippingCalc.getShippingCalcValue();
      },
      'onFailure': function() {
        console.log('error');
      }
    });
  }
};
//Get default shipping address
site.shippingCalc.getDefaultShippingAddress = function(addressArray) {
  var addressData = $.grep(addressArray, function(a) {
    return a.DEFAULT_SHIPPING === 1;
  });
  return addressData[0] ? addressData[0] : addressArray[0];
};
//Get users Address
site.shippingCalc.getuserAddress = function() {
  try {
    generic.jsonrpc.fetch({
      'method': 'user.addresses',
      'params': [{}],
      'onSuccess': function(d) {
        var shippingData = d.getValue();
        site.shippingCalc.addressBookShippingCalc(shippingData);
      },
      'onFailure': function(d) {
        console.log('error');
      }
    });
  } catch (err) {
    console.log(err.message);
  }
};

site.pickPointGetLocationsList = function(rpcMethod) {
  rpcMethod = rpcMethod || 'pickpoint.getLocationsList';
  generic.jsonrpc.fetch({
    method: rpcMethod,
    params: [{}],
    onSuccess: function(jsonRpcResponse) {
      site.countryInfo = jsonRpcResponse.getValue();
      var predefinedRegions = ['МОСКВА', 'САНКТ-ПЕТЕРБУРГ'];
      var predefinedCities = ['МОСКВА', 'САНКТ-ПЕТЕРБУРГ'];
      var fields = ['RegionName', 'САНКТ-ПЕТЕРБУРГ'];
      var customSort = function(array, predefined, field) {
        var _getValue = function(obj) {
          var value = '';
          $.each(obj, function(key, val) {
            var a = key, b = val;
            if (key === field) value = val;
          });
          return value;
        };
        array.sort(function(a, b) {
          var aVal = typeof a === 'object' ? _getValue(a) : a;
          var bVal = typeof b === 'object' ? _getValue(b) : b;
          var ai = predefined.indexOf(aVal);
          var bi = predefined.indexOf(bVal);
          if (ai >= 0 && bi < 0) {
            return -1;
          } else if (ai < 0 && bi >= 0) {
            return 1;
          } else if (ai >= 0 && bi >= 0) {
            return ai === bi ? 0 : ai > bi ? 1 : -1;
          } else {
            return aVal === bVal ? 0 : aVal > bVal ? 1 : -1;
          }
        });
        return array;
      };
      site.countryInfo.regions = site.countryInfo.regions.filter(function(item) {
        return typeof item.Zones !== 'undefined' && typeof item.Zones.all !== 'undefined' ? Boolean(item.Zones.all) : true;
      });
      site.countryInfo.regions = customSort(site.countryInfo.regions, predefinedRegions, 'RegionName');
      for (var i = site.countryInfo.regions.length; i--;) {
        var cities = site.countryInfo.regions[i].Cities;
        cities = customSort(cities, predefinedCities, '');
        site.countryInfo.regions[i].Cities = cities;
      }
      if (site.hasInitLocationDropdown) {
        site.initLocationDropdownOptions(site.selectNodes);
      }
    },
    onFailure: function(jsonRpcResponse) {
      console.log(jsonRpcResponse.getMessages());
    }
  });
};

/**
    * This method initalizes the Location dropdown menu items to display
    * the correct city depending on the region selected.
    * All select node parameters are required. 
    * @param {Object} args
    * @param {DOM node SELECT} args.regionSelectNode is the region select node.
    * @param {DOM node SELECT} args.citySelectNode is the city select node.
    * @methodOf site
*/
site.initLocationDropdownOptions = function(args) {
  var options = $.extend({}, args || {});
  // check if required nodes have been passed
  if (!options.regionSelectNode[0] || !options.citySelectNode[0]) {
    return null;
  }
  // get language resource bundle keys
  site.rb_please_select = 'Пожалуйста, выберите';
  site.rb_select_metro = 'Пожалуйста, выберите';
  site.blankOptionReq = '<option value = 0>' + site.rb_please_select + '</option>';
  site.blankOption = '<option value = 0>' + site.rb_please_select + '</option>';
  site.blankOptionSelectMetro = '<option value = 0>' + site.rb_select_metro + '</option>';
  var shipping_data = generic.cookie('shippingResult');
  var city_value = shipping_data && shipping_data['city'] ? shipping_data['city'].split(',') : 0;
  site.regionVal = shipping_data && shipping_data['COUNTRY_ID'] === '81' && shipping_data['REGION'] !== '0' ? shipping_data['REGION'] : options.regionValue || site.getSelectValue(options.regionSelectNode);
  site.cityVal = shipping_data && shipping_data['COUNTRY_ID'] === '81' ? city_value[0] : options.cityValue || site.getSelectValue(options.citySelectNode);
  if ($('select#form--shipping_calculator--field--COUNTRY_ID').val() !== '81') {
    $('.region-container').hide();
    $('.city-container').hide();
  } else {
    $('.region-container').show();
    $('.city-container').show();
  }
  $('select#form--shipping_calculator--field--COUNTRY_ID').on('change', function() {
    if ($(this).val() !== '81') {
      $('.region-container').hide();
      $('.city-container').hide();
    } else {
      $('.region-container').show();
      $('.city-container').show();
    }
  });
  site.selectNodes = options;
  site.hasInitLocationDropdown = 1;
  if (!site.countryInfo['regions']) return;
  site.setRegionSelectMenu(options);
  options.regionSelectNode.on('change', function(evt) {
    var regionSelectValue = options.regionSelectNode.val();
    var region_item = site.getRegionSelected(site.countryInfo['regions'], regionSelectValue);
    if (regionSelectValue) {
      var citiesHash = region_item.Cities;
      var citiesOrganized = citiesHash.sort();
      var tmpCity = false;
      var citiesOrganizedNew = [];
      var CityMain1 = 'МОСКВА';
      var CityMain2 = 'САНКТ-ПЕТЕРБУРГ';
      for (var a1 = 0; a1 < citiesOrganized.length; a1++) {
        if (citiesOrganized[a1] === CityMain1 || citiesOrganized[a1] === CityMain2) {
          tmpCity = true;
        }
      }
      if (tmpCity === true) {
        for (var a2 = 0; a2 < citiesOrganized.length; a2++) {
          if (citiesOrganized[a2] === CityMain1 || citiesOrganized[a2] === CityMain2) {
            citiesOrganizedNew[0] = citiesOrganized[a2];
          }
        }
        var tmpTest = false;
        var countCity = 0;
        var newCountCity = 0;
        for (var a4 = 1; a4 < citiesOrganized.length + 1; a4++) {
          if (citiesOrganized[a4 - 1] !== CityMain1 && citiesOrganized[a4 - 1] !== CityMain2) {
            if (tmpTest !== true) {
              newCountCity = a4 + countCity;
              citiesOrganizedNew[newCountCity] = citiesOrganized[a4 - 1];
            } else {
              countCity = countCity - 1;
              newCountCity = a4 + countCity;
              citiesOrganizedNew[newCountCity] = citiesOrganized[a4 - 1];
              tmpTest = false;
            }
          } else {
            tmpTest = true;
          }
        }
      } else {
        citiesOrganizedNew = citiesOrganized;
      }
      site.loadSelectMenu({
        selectNode: options.citySelectNode,
        infoHash: citiesOrganizedNew
      });
    }
  });
};

site.getRegionSelected = function(region_array, region_value) {
  var regNameWithExtraChar = region_value;
  if (region_value[region_value.length - 1] === '.') {
    regNameWithExtraChar = regNameWithExtraChar.replace(/\.$/, '');
  } else {
    regNameWithExtraChar = regNameWithExtraChar + '.';
  }
  var index = $.map(region_array, function(e, index) {
    if (e.RegionName === region_value || e.RegionName === regNameWithExtraChar || e.RegionName.toUpperCase() === region_value.toUpperCase() || e.RegionName.toUpperCase() === regNameWithExtraChar.toUpperCase()) {
      return index;
    }
  });
  var region_item = site.countryInfo['regions'][index];
  return region_item;
};

/**
    * This method is used on init to check the region values passed.  If
    * they do exist, it preloads the select menus and sets the indexes
    * of each.
    * regionValue, cityValue and districtValue are required. 
    * @param {options} args
    * @param {DOM node SELECT} options.regionValue is name of the province found
    * in the embed page address data.
    * @param {DOM node SELECT} options.cityValue is name of the city found
    * in the embed page address data.
    * @methodOf site
*/
site.setRegionSelectMenu = function(options) {
  var regions_array = site.countryInfo['regions'] || [];
  //site.regionVal = 'Алтайский край'; // for testing when get default value
  var arrayRegion = [];
  var arrayObjectRegion = [];
  var regionMain1 = 'МОСКВА';
  var regionMain2 = 'САНКТ-ПЕТЕРБУРГ';
  for (var ai = 0; ai < regions_array.length; ai++) {
    arrayRegion[ai] = regions_array[ai].RegionName;
  }
  var regionOrganized = arrayRegion.sort();
  for (var j = 0; j < regionOrganized.length; j++) {
    for (var k = 0; k < regions_array.length; k++) {
      if (regionOrganized[j] === regions_array[k].RegionName) {
        arrayObjectRegion[j] = regions_array[k];
      }
    }
  }
  var arrayObjectRegionTop = [];
  for (var l = 0; l < arrayObjectRegion.length; l++) {
    if (arrayObjectRegion[l].RegionName === regionMain1) {
      arrayObjectRegionTop[0] = arrayObjectRegion[l];
    }
  }
  for (var m = 0; m < arrayObjectRegion.length; m++) {
    if (arrayObjectRegion[m].RegionName === regionMain2) {
      arrayObjectRegionTop[1] = arrayObjectRegion[m];
    }
  }
  var tmpR = false;
  var count = 0;
  var newCount = 0;
  for (var n = 2; n < arrayObjectRegion.length + 2; n++) {
    if (arrayObjectRegion[n - 2].RegionName !== regionMain1 && arrayObjectRegion[n - 2].RegionName !== regionMain2) {
      if (tmpR !== true) {
        newCount = n + count;
        arrayObjectRegionTop[newCount] = arrayObjectRegion[n - 2];
      } else {
        count = count - 1;
        newCount = n + count;
        arrayObjectRegionTop[newCount] = arrayObjectRegion[n - 2];
        tmpR = false;
      }
    } else {
      tmpR = true;
    }
  }
  site.loadSelectMenu({
    selectNode: options.regionSelectNode,
    infoHash: arrayObjectRegionTop,
    value: site.regionVal,
    key: 'RegionName'
  });

  var region_item = site.getRegionSelected(regions_array, site.regionVal);
  if (site.regionVal) {
    var citiesHash = region_item.Cities;
    site.loadSelectMenu({
      selectNode: options.citySelectNode,
      infoHash: citiesHash,
      value: site.cityVal
    });
  }
};

site.initLocationDropdownOptionsYMapOverlay = function(options) {
  var region_item = site.getRegionSelected(site.countryInfo['regions'], options.regionYMapValue);
  if (options.regionYMapValue) {
    var citiesHash = region_item.Cities;
    var citiesOrganized = citiesHash.sort();
    var tmpCity = false;
    var citiesOrganizedNew = [];
    var CityMain1 = 'МОСКВА';
    var CityMain2 = 'САНКТ-ПЕТЕРБУРГ';
    for (var a1 = 0; a1 < citiesOrganized.length; a1++) {
      if (citiesOrganized[a1] === CityMain1 || citiesOrganized[a1] === CityMain2) {
        tmpCity = true;
      }
    }
    if (tmpCity === true) {
      for (var a2 = 0; a2 < citiesOrganized.length; a2++) {
        if (citiesOrganized[a2] === CityMain1 || citiesOrganized[a2] === CityMain2) {
          citiesOrganizedNew[0] = citiesOrganized[a2];
        }
      }
      var tmpTest = false;
      var countCity = 0;
      var newCountCity = 0;
      for (var a4 = 1; a4 < citiesOrganized.length + 1; a4++) {
        if (citiesOrganized[a4 - 1] !== CityMain1 && citiesOrganized[a4 - 1] !== CityMain2) {
          if (tmpTest !== true) {
            newCountCity = a4 + countCity;
            citiesOrganizedNew[newCountCity] = citiesOrganized[a4 - 1];
          } else {
            countCity = countCity - 1;
            newCountCity = a4 + countCity;
            citiesOrganizedNew[newCountCity] = citiesOrganized[a4 - 1];
            tmpTest = false;
          }
        } else {
          tmpTest = true;
        }
      }
    } else {
      citiesOrganizedNew = citiesOrganized;
    }
    site.loadSelectMenu({
      selectNode: options.cityYMapNode,
      infoHash: citiesOrganizedNew,
      value: options.cityYMapValue
    });
  }
};

/**
    * This method is used to populate the select menu specified in selectNode
    * with the passed local information in infoHash.
    * Automatically removes the preexisting select options.
    * Inserts the blankOption variable defined in the init method.
    * Also checks the infoHash to see if it is array or not.
    * Once that check is in place, it populates the defined select node
    * in selectNode with the infoHash.
    * @param {Object} selectNode **REQUIRED**
    * @param {Object} infoHash **REQUIRED**    
    * @methodOf site
*/
site.loadSelectMenu = function(args) {
  var selectNode = args.selectNode;
  var infoHash = args.infoHash;
  var value = args.value;
  var resetNodes = args.resetNodes;
  var key_val = args.key;
  var item;
  if (selectNode && infoHash) {
    site.resetDropDown(selectNode);
    if (resetNodes) {
      $(resetNodes).each(function() {
        var node = this;
        site.resetDropDown(node);
      });
    }

    if (Array.isArray(infoHash)) {
      for (item = 0; item < infoHash.length; item++) {
        var hashVal = infoHash[item];
        if (typeof hashVal !== 'undefined') {
          if (key_val) {
            hashVal = infoHash[item][key_val];
          }
          generic.forms.select.addOption({
            value: hashVal,
            menuNode: selectNode
          });
        }
      }
    } else {
      for (item in infoHash) {
        generic.forms.select.addOption({
          value: item,
          menuNode: selectNode
        });
      }
    }

    if (value) {
      //$(document).trigger("select:loaded", args);
      generic.forms.select.setValue({
        menuNode: args.selectNode,
        value: args.value
      });
    } else {
      selectNode.selectedIndex = 0;
    }
  }
};

// For IE since sometimes the setValue method gets called
// before the select menu finishes loading.
$(document).on('select:loaded', function(event, args) {
  generic.forms.select.setValue({
    menuNode: args.selectNode,
    value: args.value
  });
});

/**
    * Returns the location value if added in the class of the
    * select node passed or returns an emtpy string.
    * @param {node} selectNode **REQUIRED**
    * @methodOf site
*/
site.getSelectValue = function(node) {
  // retrieve address ID from element class name
  var nodeClasses = node.attr('value');
  var reResults = nodeClasses.match(/([\u4e00-\u9fa5]+)/);
  var selectVal = '';
  if (reResults) {
    selectVal = reResults[1];
  }
  return selectVal;
};

/**
    * Method to clear out specified select menus passed in by the node param.
    * @param {node} individual selectNode **REQUIRED**
    * @methodOf site
*/
site.resetDropDown = function(node) {
  if (node) {
    var option;
    //var option = (node.attr('NAME') != 'DISTRICT') ? site.blankOptionReq : site.blankOption;
    if (node.attr('NAME') === 'SELECT_OPTION_METRO') {
      option = site.blankOptionSelectMetro;
    } else if (node.attr('NAME') !== 'DISTRICT') {
      option = site.blankOptionReq;
    } else {
      option = site.blankOption;
    }
    node.html('');
    node.html(option);
  }
};
